import { RouteRecordRaw } from "vue-router";

export const userRoutes: Array<RouteRecordRaw> = [
  {
    path: "/users",
    name: "UserList",
    component: () => import("../views/UserList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "user",
      requiredRights: ["readAll", "readOwn"],
    },
  },
  {
    path: "/users/:id",
    name: "UserEdit",
    component: () => import("../views/UserEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "user",
      requiredRights: ["readAll", "readOwn", "updateAll", "updateOwn"],
    },
  },
];
