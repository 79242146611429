import firebase from "firebase/app";

import { IExportOptions } from "./helpers";

import { getReference } from "@/core/modules/helpers";

export const exportBackup = async (collectionsArray: string[], options?: IExportOptions): Promise<unknown> => {
  const promises: Promise<unknown>[] = [];
  collectionsArray.forEach((collection: string) => {
    const result = exportCollectionBackup(collection, options);
    promises.push(result);
  });

  const value = await Promise.all(promises);
  const all = Object.assign({}, ...value);
  return all;
};

export const exportCollectionBackup = async (collectionName: string, options?: IExportOptions): Promise<Record<string, Record<string, unknown>>> => {
  try {
    const data: Record<string, Record<string, unknown>> = {};
    data[collectionName] = {};

    const documents: firebase.firestore.QuerySnapshot<unknown> =
      options?.queryCollection != null ? await options.queryCollection(getReference(collectionName)) : await getReference(collectionName).get();

    const docs: firebase.firestore.QueryDocumentSnapshot<unknown>[] =
      options?.docsFromEachCollection != undefined && options?.docsFromEachCollection > 0
        ? documents.docs.slice(0, options?.docsFromEachCollection)
        : documents.docs;

    for (const doc of docs) {
      data[collectionName][doc.id] = doc.data();
    }

    return data;
  } catch (error) {
    console.error(error);
    throw new Error(error as string);
  }
};
