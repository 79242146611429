
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";

import { CollectionInterface } from "@/core/modules/database/models/Collection.interface";
import { koruStoreTypes } from "@/core/modules/store/types";
import { useKoruLocale } from "@/core/modules/locale";
import { useKoruStore } from "@/core/modules/store";

import {
  goToErrorPage,
  showCloseConfirmDialog,
  showDeleteConfirmDialog,
  showToastWithErrorType,
  showToastWithSuccessType,
  showToastWithWarningType,
  userCanCreate,
  userCanDelete,
  userCanUpdate,
} from "@/core/modules/helpers";

export default defineComponent({
  name: "KoruEditor",
  props: {
    backUrl: { type: String, default: "/" },
    // eslint-disable-next-line prettier/prettier
    deleteAction: { type: Function, default: () => { return; } },
    formSubmitted: { type: Boolean, default: false },
    isFormValid: { type: Boolean, default: false },
    // eslint-disable-next-line prettier/prettier
    loadAction: { type: Function, default: () => { return; } },
    parentQuery: { type: String, default: "" },
    record: { type: Object, default: undefined },
    // eslint-disable-next-line prettier/prettier
    submitAction: { type: Function, default: () => { return; } },
  },
  emits: ["update:formSubmitted"],
  setup(props, { emit }) {
    const { t } = useKoruLocale();
    const koruStore = useKoruStore();

    const route = useRoute();
    const requiredModule: string = route.meta.requiredModule as string;

    const formSubmittedRef = computed({
      get: () => props.formSubmitted,
      set: (val) => emit("update:formSubmitted", val),
    });

    const renderTitlePrefix = () => {
      if (props.record?.id == "new") {
        return t("gen.newPrefix");
      } else if (userCanUpdate(requiredModule, props.record as CollectionInterface)) {
        return t("gen.editPrefix");
      } else {
        return t("gen.viewPrefix");
      }
    };

    const handleLoad = async () => {
      try {
        await props.loadAction();
      } catch (error: unknown) {
        console.log((error as Error).message);
        goToErrorPage((error as Error).message);
      } finally {
        koruStore.commit(koruStoreTypes.mutations.loadingStop);
        koruStore.commit(koruStoreTypes.mutations.editingStart);
      }
    };

    const handleSubmit = async () => {
      formSubmittedRef.value = true;
      if (!props.isFormValid) {
        showToastWithWarningType("formInvalid", t);
        return;
      }
      koruStore.commit(koruStoreTypes.mutations.editingStop);
      koruStore.commit(koruStoreTypes.mutations.loadingStart);
      try {
        await props.submitAction();
        await koruStore.dispatch(koruStoreTypes.actions.navigateTo, props.backUrl + props.parentQuery);
        showToastWithSuccessType("save", t);
      } catch (error: unknown) {
        console.log((error as Error).message);
        showToastWithErrorType((error as Error).message, "save", t);
        koruStore.commit(koruStoreTypes.mutations.loadingStop);
      }
    };

    const handleDelete = async () => {
      koruStore.commit(koruStoreTypes.mutations.editingStop);
      koruStore.commit(koruStoreTypes.mutations.loadingStart);

      try {
        await props.deleteAction();
        await koruStore.dispatch(koruStoreTypes.actions.navigateTo, props.backUrl + props.parentQuery);
        showToastWithSuccessType("delete", t);
      } catch (error: unknown) {
        console.log((error as Error).message);
        showToastWithErrorType((error as Error).message, "delete", t);
        koruStore.commit(koruStoreTypes.mutations.loadingStop);
      }
    };

    handleLoad();

    return {
      props,
      handleDelete,
      handleSubmit,
      koruStore,
      koruStoreTypes,
      renderTitlePrefix,
      requiredModule,
      t,
      userCanCreate,
      userCanDelete,
      userCanUpdate,
    };
  },
  methods: {
    confirmClose(event: Event) {
      showCloseConfirmDialog(
        event,
        () => {
          this.koruStore.commit(this.koruStoreTypes.mutations.editingStop);
          this.koruStore.dispatch(this.koruStoreTypes.actions.navigateTo, this.$props.backUrl + this.$props.parentQuery);
        },
        this.t
      );
    },
    confirmDelete(event: Event) {
      showDeleteConfirmDialog(
        event,
        () => {
          this.handleDelete();
        },
        this.t
      );
    },
  },
});
