import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { getObjectValueWithField, hasCollectionChanged, setCollectionFields } from "@/core/modules/helpers";
import { koruDb } from "@/core/modules/database";
import { koruConfig } from "@/core/modules/config";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { RoleInterface } from "../models/Role.interface";
import { RoleModelInterface } from "@/core/modules/database/models/RoleModel.interface";
import { roleStoreTypes } from "./types";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { userStore } from "@/core/modules/user/store";
import { userStoreTypes } from "@/core/modules/user/store/types";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [roleStoreTypes.actions.getRoles](): Promise<RoleInterface[]> {
    try {
      return await koruDb.getModule<RoleModelInterface>("role").getRoles();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [roleStoreTypes.actions.getRole](context, roleId: string): Promise<RoleInterface> {
    try {
      return await koruDb.getModule<RoleModelInterface>("role").getRole(roleId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [roleStoreTypes.actions.createRole](context, role: RoleInterface): Promise<void> {
    try {
      setCollectionFields(role.id, role);
      await koruDb.getModule<RoleModelInterface>("role").createRole(role);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [roleStoreTypes.actions.updateRole](context, role: RoleInterface): Promise<void> {
    try {
      const oldRole: RoleInterface = await koruDb.getModule<RoleModelInterface>("role").getRole(role.id);
      if (hasCollectionChanged(role, oldRole)) throw new Error("sync");

      setCollectionFields(role.id, role);
      await koruDb.getModule<RoleModelInterface>("role").updateRole(role);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [roleStoreTypes.actions.deleteRole](context, roleId: string): Promise<void> {
    try {
      const users = (await userStore.action(userStoreTypes.actions.getUsersByRole, roleId)) as UserInterface[];
      if (users.length > 0) throw new Error("children");

      await koruDb.getModule<RoleModelInterface>("role").deleteRole(roleId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  [roleStoreTypes.actions.fromValuesToMatrix](context, role: RoleInterface): Record<string, unknown>[] {
    const roleModules = koruConfig.authentication.roleModules;
    const matrix: Record<string, unknown>[] = [];

    roleModules.forEach((roleModule: string) => {
      const roleObject: Record<string, unknown> = { roleModule: roleModule };
      if (role == undefined) {
        koruConfig.authentication.rights.forEach((right: string) => {
          roleObject[right] = false;
        });
      } else {
        koruConfig.authentication.rights.forEach((right: string) => {
          roleObject[right] = (getObjectValueWithField(role, right) as string[]).includes(roleModule) ? true : false;
        });
      }
      matrix.push(roleObject);
    });

    return matrix;
  },
  [roleStoreTypes.actions.fromMatrixToValues](context, payload: Record<string, unknown>): RoleInterface {
    const role = payload.record as RoleInterface;
    const matrix = payload.matrix as Record<string, unknown>[];

    matrix.forEach((moduleValues: Record<string, unknown>) => {
      const roleModule: string = moduleValues.roleModule as string;
      koruConfig.authentication.rights.forEach((right: string) => {
        if (moduleValues[right] == true) {
          if (!(getObjectValueWithField(role, right) as Array<string>).includes(roleModule)) {
            (getObjectValueWithField(role, right) as Array<string>).push(roleModule);
          }
        } else {
          const roleIndex = (getObjectValueWithField(role, right) as Array<string>).indexOf(roleModule, 0);
          if (roleIndex > -1) {
            (getObjectValueWithField(role, right) as Array<string>).splice(roleIndex, 1);
          }
        }
      });
    });

    return role;
  },
};
