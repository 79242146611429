export const authStoreTypes = {
  getters: {
    isLoading: "IS_LOADING",
    isLoggedIn: "IS_LOGGED_IN",
    getUser: "GET_USER",
  },
  actions: {
    login: "LOGIN",
    logout: "LOGOUT",
    forgotPassword: "FORGOT_PASSWORD",
    recoverPassword: "RECOVER_PASSWORD",
    changePassword: "CHANGE_PASSWORD",
    changeEmail: "CHANGE_EMAIL",
    changeTheme: "CHANGE_THEME",
    deleteUser: "DELETE_USER",
  },
  mutations: {
    loadingStart: "LOADING_START",
    loadingStop: "LOADING_STOP",
    loginSuccess: "LOGIN_SUCCESS",
    loginError: "LOGIN_ERROR",
    logoutSuccess: "LOGOUT_SUCCESS",
  },
};
