import { GetterTree } from "vuex";

import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { LocaleInterface } from "../models/Locale.interface";
import { LocaleStateInterface } from "../models/LocaleState.interface";
import { localeStoreTypes } from "./types";
import { UserInterface } from "@/core/modules/user/models/User.interface";

export const getters: GetterTree<LocaleStateInterface, KoruStateInterface> = {
  [localeStoreTypes.getters.getSelectedLocale]: (state: LocaleStateInterface): LocaleInterface => {
    const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);

    let selectedLocale: LocaleInterface = state.locales[0];
    state.locales.forEach((locale: LocaleInterface) => {
      if (locale.name == user.detail.locale) {
        selectedLocale = locale;
        return;
      }
    });

    return selectedLocale;
  },
  [localeStoreTypes.getters.getLocales]: (state: LocaleStateInterface): LocaleInterface[] => {
    return state.locales;
  },
};
