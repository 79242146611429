import { LocaleMessageValue, RemoveIndexSignature } from "@intlify/core-base";
import { ComposerTranslation, VueMessageType } from "vue-i18n";

import { koruApp } from "@/main";

export function showCloseConfirmDialog(
  event: Event,
  confirmAction: () => void,
  t?: ComposerTranslation<unknown, unknown, RemoveIndexSignature<{ [x: string]: LocaleMessageValue<VueMessageType> }>, never, never, never>
): void {
  const confirm = koruApp.config.globalProperties.$confirm;

  confirm.require({
    target: event.currentTarget,
    message: t != undefined ? t("dialog.confirmCloseWithoutSave") : "dialog.confirmCloseWithoutSave",
    icon: "pi pi-exclamation-circle",
    accept: () => {
      confirmAction();
    },
    acceptLabel: t != undefined ? t("gen.yes") : "yes",
    rejectLabel: t != undefined ? t("gen.no") : "no",
  });
}

export function showDeleteConfirmDialog(
  event: Event,
  confirmAction: () => void,
  t?: ComposerTranslation<unknown, unknown, RemoveIndexSignature<{ [x: string]: LocaleMessageValue<VueMessageType> }>, never, never, never>
): void {
  const confirm = koruApp.config.globalProperties.$confirm;

  confirm.require({
    target: event.currentTarget,
    message: t != undefined ? t("dialog.confirmDelete") : "dialog.confirmDelete",
    icon: "pi pi-times-circle",
    accept: () => {
      confirmAction();
    },
    acceptClass: "p-button-danger",
    acceptLabel: t != undefined ? t("gen.yes") : "yes",
    rejectLabel: t != undefined ? t("gen.no") : "no",
  });
}
