export const workbenchStoreTypes = {
  getters: {
    getUploadedData: "GET_UPLOADED_DATA",
  },
  actions: {
    deleteData: "DELETE_DATA",
    exportData: "EXPORT_DATA",
    importData: "IMPORT_DATA",
    sendMail: "SEND_MAIL",
  },
  mutations: {
    resetUploadedData: "RESET_UPLOADED_DATA",
    setUploadedData: "SET_UPLOADED_DATA",
  },
};
