import { createStoreModule } from "@/core/modules/helpers";
import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { KoruStoreModuleInterface } from "@/core/modules/store/models/KoruStoreModule.interface";

import { actions } from "./actions";

// eslint-disable-next-line prettier/prettier
export const roleStore: KoruStoreModuleInterface<EmptyStateInterface> = createStoreModule(
  "roleState",
  undefined,
  undefined,
  actions
);

export const useRoleStore = (): KoruStoreModuleInterface<EmptyStateInterface> => {
  return roleStore;
};
