import firebase from "firebase";

import { PageInterface, pageConverter } from "@/features/modules/page/models/Page.interface";
import { PageModelInterface } from "../models/PageModel.interface";

import { getReference } from "@/core/modules/helpers";

export const pageModel: PageModelInterface = {
  name: "page",

  async getPages(): Promise<PageInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<PageInterface> = await getReference("pages")
        .withConverter(pageConverter)
        .orderBy("titleIt")
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: PageInterface[] = [];
      snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<PageInterface>) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getPage(pageId: string): Promise<PageInterface> {
    try {
      // eslint-disable-next-line prettier/prettier
      const doc: firebase.firestore.DocumentSnapshot<PageInterface> = await getReference("pages")
        .withConverter(pageConverter)
        .doc(pageId)
        .get();

      if (doc.exists) {
        return doc.data() as PageInterface;
      } else {
        throw new Error(`Page #${pageId} not found`);
      }
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async createPage(page: PageInterface, forceId = false): Promise<void> {
    try {
      if (forceId) {
        // eslint-disable-next-line prettier/prettier
        await getReference("pages")
          .withConverter(pageConverter)
          .doc(page.id)
          .set(page);
      } else {
        // eslint-disable-next-line prettier/prettier
        await getReference("pages")
          .withConverter(pageConverter)
          .doc()
          .set(page);
      }
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async updatePage(page: PageInterface): Promise<void> {
    try {
      // eslint-disable-next-line prettier/prettier
      await getReference("pages")
        .withConverter(pageConverter)
        .doc(page.id)
        .set(page);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async deletePage(pageId: string): Promise<void> {
    try {
      // eslint-disable-next-line prettier/prettier
      await getReference("pages")
        .doc(pageId)
        .delete();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
