import { RouteRecordRaw } from "vue-router";

export const recipeRoutes: Array<RouteRecordRaw> = [
  {
    path: "/recipes",
    name: "RecipeList",
    props: (route) => ({ categoryId: route.query.category }),
    component: () => import("../views/RecipeList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "recipe",
      requiredRights: ["readAll", "readOwn"],
    },
  },
  {
    path: "/recipes/:id",
    name: "RecipeEdit",
    component: () => import("../views/RecipeEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "recipe",
      requiredRights: ["readAll", "readOwn", "updateAll", "updateOwn"],
    },
  },
];
