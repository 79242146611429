import { MutationTree } from "vuex";

import { RecipeStateInterface } from "../models/RecipeState.interface";
import { recipeStoreTypes } from "./types";

export const mutations: MutationTree<RecipeStateInterface> = {
  [recipeStoreTypes.mutations.uploadStart]: (state: RecipeStateInterface): void => {
    state.isUploading = true;
    state.uploadProgressValue = 0;
  },
  [recipeStoreTypes.mutations.uploadStop]: (state: RecipeStateInterface): void => {
    state.isUploading = false;
    state.uploadProgressValue = 0;
  },
  [recipeStoreTypes.mutations.setUploadProgressValue]: (state: RecipeStateInterface, value: number): void => {
    state.uploadProgressValue = value;
  },
};
