import { RouteRecordRaw } from "vue-router";

export const recipeCategoryRoutes: Array<RouteRecordRaw> = [
  {
    path: "/recipe-categories",
    name: "RecipeCategoryList",
    component: () => import("../views/RecipeCategoryList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "recipeCategory",
      requiredRights: ["readAll", "readOwn"],
    },
  },
  {
    path: "/recipe-categories/:id",
    name: "RecipeCategoryEdit",
    component: () => import("../views/RecipeCategoryEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "recipeCategory",
      requiredRights: ["readAll", "readOwn", "updateAll", "updateOwn"],
    },
  },
];
