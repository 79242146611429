import { GetterTree } from "vuex";

import { RecipeStateInterface } from "../models/RecipeState.interface";
import { recipeStoreTypes } from "./types";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { koruStoreTypes } from "@/core/modules/store/types";

export const getters: GetterTree<RecipeStateInterface, KoruStateInterface> = {
  [recipeStoreTypes.getters.isLoading]: (state: RecipeStateInterface, getters: GetterTree<KoruStateInterface, KoruStateInterface>): boolean => {
    return getters[koruStoreTypes.getters.isLoading] as unknown as boolean;
  },
  [recipeStoreTypes.getters.isUploading]: (state: RecipeStateInterface): boolean => {
    return state.isUploading;
  },
  [recipeStoreTypes.getters.getUploadProgressValue]: (state: RecipeStateInterface): number => {
    return state.uploadProgressValue;
  },
};
