import { koruConfig } from "@/core/modules/config";

import {
  CollectionInterface,
  createCollection,
  collectionFromFirestore,
  collectionToFirestore,
} from "@/core/modules/database/models/Collection.interface";
// eslint-disable-next-line prettier/prettier
import {
  linkedRoleFromFirestore,
  LinkedRoleInterface,
  linkedRoleToFirestore
} from "@/core/modules/role/models/LinkedRole.interface";
// eslint-disable-next-line prettier/prettier
import {
  userDetailFromFirestore,
  UserDetailInterface,
  userDetailToFirestore
} from "./UserDetail.interface";

import { createFirestoreConverter } from "@/core/modules/helpers";

export interface UserInterface extends CollectionInterface {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  role: LinkedRoleInterface;
  detail: UserDetailInterface;
  blocked: boolean;
}

export const createNewUser = (): UserInterface => {
  return createCollection<UserInterface>({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    role: undefined,
    detail: {
      locale: koruConfig.locale.default,
      theme: koruConfig.layout.defaultTheme,
    },
    blocked: false,
  });
};

export function userFromFirestore(data: Record<string, unknown>, id?: string): UserInterface {
  return collectionFromFirestore<UserInterface>(
    id != undefined ? id : (data.id as string),
    {
      firstName: data.firstName || undefined,
      lastName: data.lastName || undefined,
      email: data.email || undefined,
      role: linkedRoleFromFirestore(data.role as Record<string, unknown>),
      detail: userDetailFromFirestore(data.detail as Record<string, unknown>),
      blocked: data.blocked,
    },
    data
  );
}

export function userToFirestore(user: UserInterface, id?: string): Record<string, unknown> {
  return collectionToFirestore(
    Object.assign(id != undefined ? { id: id } : {}, {
      firstName: user.firstName || null,
      lastName: user.lastName || null,
      email: user.email || null,
      role: linkedRoleToFirestore(user.role),
      detail: userDetailToFirestore(user.detail),
      blocked: user.blocked,
    }),
    user
  );
}

export const userConverter = createFirestoreConverter(userFromFirestore, userToFirestore);
