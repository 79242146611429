export const userStoreTypes = {
  actions: {
    getUsers: "GET_USERS",
    getUsersByRole: "GET_USERS_BY_ROLE",
    getUser: "GET_USER",
    createUser: "CREATE_USER",
    updateUser: "UPDATE_USER",
    toggleUser: "TOGGLE_USER",
    deleteUser: "DELETE_USER",
    isEmailUnique: "IS_EMAIL_UNIQUE",
  },
};
