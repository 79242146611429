import firebase from "firebase";

import { firebaseFirestore } from "@/core/plugins/firebase";
import { koruDb } from "@/core/modules/database";
import { linkedRecipeCategoryToFirestore } from "@/features/modules/recipe-category/models/LinkedRecipeCategory.interface";
import { RecipeCategoryInterface, recipeCategoryConverter } from "@/features/modules/recipe-category/models/RecipeCategory.interface";
import { RecipeCategoryModelInterface } from "../models/RecipeCategoryModel.interface";
import { RecipeInterface } from "@/features/modules/recipe/models/Recipe.interface";
import { RecipeModelInterface } from "../models/RecipeModel.interface";

import { getReference } from "@/core/modules/helpers";

export const recipeCategoryModel: RecipeCategoryModelInterface = {
  name: "recipeCategory",

  async getRecipeCategories(): Promise<RecipeCategoryInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<RecipeCategoryInterface> = await getReference("recipeCategories")
        .withConverter(recipeCategoryConverter)
        .orderBy("order")
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: RecipeCategoryInterface[] = [];
      snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<RecipeCategoryInterface>) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getRecipeCategory(recipeCategoryId: string): Promise<RecipeCategoryInterface> {
    try {
      // eslint-disable-next-line prettier/prettier
      const doc: firebase.firestore.DocumentSnapshot<RecipeCategoryInterface> = await getReference("recipeCategories")
        .withConverter(recipeCategoryConverter)
        .doc(recipeCategoryId)
        .get();

      if (doc.exists) {
        return doc.data() as RecipeCategoryInterface;
      } else {
        throw new Error(`RecipeCategory #${recipeCategoryId} not found`);
      }
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async createRecipeCategory(recipeCategory: RecipeCategoryInterface, forceId = false): Promise<void> {
    try {
      if (forceId) {
        // eslint-disable-next-line prettier/prettier
        await getReference("recipeCategories")
          .withConverter(recipeCategoryConverter)
          .doc(recipeCategory.id)
          .set(recipeCategory);
      } else {
        // eslint-disable-next-line prettier/prettier
        await getReference("recipeCategories")
          .withConverter(recipeCategoryConverter)
          .doc()
          .set(recipeCategory);
      }
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async updateRecipeCategory(recipeCategory: RecipeCategoryInterface): Promise<void> {
    try {
      const batch = firebaseFirestore.batch();

      batch.set(getReference("recipeCategories").withConverter(recipeCategoryConverter).doc(recipeCategory.id), recipeCategory);

      const linkedRecipeCategory = linkedRecipeCategoryToFirestore({
        id: recipeCategory.id,
        titleEn: recipeCategory.titleEn,
        titleIt: recipeCategory.titleIt,
        slugEn: recipeCategory.slugEn,
        slugIt: recipeCategory.slugIt,
      });

      const recipes: RecipeInterface[] = await koruDb.getModule<RecipeModelInterface>("recipe").getRecipesByCategory(recipeCategory.id);
      recipes.forEach((recipe: RecipeInterface) => {
        batch.update(getReference("recipes").doc(recipe.id), { category: linkedRecipeCategory });
      });

      await batch.commit();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async deleteRecipeCategory(recipeCategoryId: string): Promise<void> {
    try {
      // eslint-disable-next-line prettier/prettier
      await getReference("recipeCategories")
        .doc(recipeCategoryId)
        .delete();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
