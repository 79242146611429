export const enFeaturesLabels = {
  buttons: {
    goToRecipes: "Go to Recipes",
  },
  navigation: {
    kitchen: "Kitchen",
    pages: "Pages",
    recipeCategories: "Recipe Categories",
    recipes: "Recipes",
    website: "Website",
  },
  roleModules: {
    page: "Pages",
    recipeCategory: "Recipes - Categories",
    recipe: "Recipes",
  },
  toast: {},
};
