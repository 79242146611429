export const notificationStoreTypes = {
  actions: {
    getNotifications: "GET_NOTIFICATIONS",
    getNotificationsByType: "GET_NOTIFICATIONS_BY_TYPE",
    getNotificationsMatrix: "GET_NOTIFICATIONS_MATRIX",
    getNotification: "GET_NOTIFICATION",
    createNotification: "CREATE_NOTIFICATION",
    updateNotification: "UPDATE_NOTIFICATION",
    deleteNotification: "DELETE_NOTIFICATION",
  },
};
