import { AuthStateInterface } from "../models/AuthState.interface";
import { createStoreModule } from "@/core/modules/helpers";
import { KoruStoreModuleInterface } from "@/core/modules/store/models/KoruStoreModule.interface";

import { actions } from "./actions";
import { getters } from "./getters";
import { initialState } from "./initial-state";
import { mutations } from "./mutations";

// eslint-disable-next-line prettier/prettier
export const authStore: KoruStoreModuleInterface<AuthStateInterface> = createStoreModule(
  "authState",
  initialState,
  getters,
  actions,
  mutations
);

export const useAuthStore = (): KoruStoreModuleInterface<AuthStateInterface> => {
  return authStore;
};
