import firebase from "firebase/app";

import { uniqueId } from "@/core/plugins/unique-id";

import { createFirestoreConverter } from "@/core/modules/helpers";

export interface NotificationInterface {
  id: string;
  type: string | undefined;
  detail: string | undefined;
  readBy: string[];
  createdAt: Date;
}

export const createNewNotification = (): NotificationInterface => {
  return {
    id: uniqueId(),
    type: undefined,
    detail: undefined,
    readBy: [],
    createdAt: new Date(),
  };
};

export function notificationFromFirestore(data: Record<string, unknown>, id?: string): NotificationInterface {
  return {
    id: id != undefined ? id : (data.id as string),
    type: (data.type as string) || undefined,
    detail: (data.detail as string) || undefined,
    readBy: (data.readBy as string[]) || [],
    createdAt: (data.createdAt as firebase.firestore.Timestamp).toDate(),
  };
}

export function notificationToFirestore(notification: NotificationInterface): Record<string, unknown> {
  return {
    type: notification.type || null,
    detail: notification.detail || null,
    readBy: notification.readBy || [],
    createdAt: firebase.firestore.Timestamp.fromDate(notification.createdAt),
  };
}

export const notificationConverter = createFirestoreConverter(notificationFromFirestore, notificationToFirestore);
