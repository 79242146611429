import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { PageInterface } from "../models/Page.interface";
import { PageModelInterface } from "@/features/modules/database/models/PageModel.interface";
import { pageStoreTypes } from "./types";

import { hasCollectionChanged, setCollectionFields, slugify } from "@/core/modules/helpers";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [pageStoreTypes.actions.getPages](): Promise<PageInterface[]> {
    try {
      return await koruDb.getModule<PageModelInterface>("page").getPages();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [pageStoreTypes.actions.getPage](context, pageId: string): Promise<PageInterface> {
    try {
      return await koruDb.getModule<PageModelInterface>("page").getPage(pageId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [pageStoreTypes.actions.createPage](context, page: PageInterface): Promise<void> {
    try {
      page.slugEn = page.titleEn != undefined ? slugify(page.titleEn) : undefined;
      page.slugIt = page.titleIt != undefined ? slugify(page.titleIt) : undefined;

      setCollectionFields(page.id, page);
      await koruDb.getModule<PageModelInterface>("page").createPage(page);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [pageStoreTypes.actions.updatePage](context, page: PageInterface): Promise<void> {
    try {
      const oldPage: PageInterface = await koruDb.getModule<PageModelInterface>("page").getPage(page.id);
      if (hasCollectionChanged(page, oldPage)) throw new Error("sync");

      page.slugEn = page.titleEn != undefined ? slugify(page.titleEn) : undefined;
      page.slugIt = page.titleIt != undefined ? slugify(page.titleIt) : undefined;

      setCollectionFields(page.id, page);
      await koruDb.getModule<PageModelInterface>("page").updatePage(page);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [pageStoreTypes.actions.deletePage](context, pageId: string): Promise<void> {
    try {
      await koruDb.getModule<PageModelInterface>("page").deletePage(pageId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
