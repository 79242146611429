import { ActionTree } from "vuex";

import { AuthStateInterface } from "../models/AuthState.interface";
import { authStoreTypes } from "./types";
import { koruAuth } from "../module";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { UserInterface } from "@/core/modules/user/models/User.interface";

export const actions: ActionTree<AuthStateInterface, KoruStateInterface> = {
  async [authStoreTypes.actions.login](context, { email, password }): Promise<void> {
    context.commit(authStoreTypes.mutations.loadingStart);

    try {
      const user: UserInterface = await koruAuth.login(email, password);
      context.commit(authStoreTypes.mutations.loginSuccess, user);
    } catch (error: unknown) {
      context.commit(authStoreTypes.mutations.loginError);
      throw new Error((error as Error).message);
    }
  },
  async [authStoreTypes.actions.logout](context): Promise<void> {
    context.commit(authStoreTypes.mutations.loadingStart);

    try {
      await koruAuth.logout();
      context.commit(authStoreTypes.mutations.logoutSuccess);
    } catch (error: unknown) {
      context.commit(authStoreTypes.mutations.loadingStop);
      throw new Error((error as Error).message);
    }
  },
  async [authStoreTypes.actions.forgotPassword](context, email) {
    context.commit(authStoreTypes.mutations.loadingStart);

    try {
      await koruAuth.forgotPassword(email);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    } finally {
      context.commit(authStoreTypes.mutations.loadingStop);
    }
  },
  async [authStoreTypes.actions.recoverPassword](context, { code, newPassword }) {
    context.commit(authStoreTypes.mutations.loadingStart);

    try {
      await koruAuth.recoverPassword(code, newPassword);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    } finally {
      context.commit(authStoreTypes.mutations.loadingStop);
    }
  },
  async [authStoreTypes.actions.changePassword](context, { oldPassword, newPassword }) {
    context.commit(authStoreTypes.mutations.loadingStart);

    try {
      await koruAuth.updatePassword(oldPassword, newPassword);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    } finally {
      context.commit(authStoreTypes.mutations.loadingStop);
    }
  },
  async [authStoreTypes.actions.changeEmail](context, { newEmail, password }) {
    context.commit(authStoreTypes.mutations.loadingStart);

    try {
      await koruAuth.updateEmail(newEmail, password);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    } finally {
      context.commit(authStoreTypes.mutations.loadingStop);
    }
  },
  async [authStoreTypes.actions.deleteUser](context, userId) {
    try {
      await koruAuth.deleteUser(userId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
