import { ActionTree } from "vuex";
import { uniqueId } from "@/core/plugins/unique-id";

import { koruConfig } from "@/core/modules/config";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { koruStorage } from "@/core/modules/storage";
import { RecipeInterface } from "../models/Recipe.interface";
import { RecipeModelInterface } from "@/features/modules/database/models/RecipeModel.interface";
import { RecipeStateInterface } from "../models/RecipeState.interface";
import { recipeStoreTypes } from "./types";

import { hasCollectionChanged, setCollectionFields, slugify } from "@/core/modules/helpers";

export const actions: ActionTree<RecipeStateInterface, KoruStateInterface> = {
  async [recipeStoreTypes.actions.getRecipes](): Promise<RecipeInterface[]> {
    try {
      return await koruDb.getModule<RecipeModelInterface>("recipe").getRecipes();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeStoreTypes.actions.getRecipesByCategory](context, recipeCategoryId: string): Promise<RecipeInterface[]> {
    try {
      return await koruDb.getModule<RecipeModelInterface>("recipe").getRecipesByCategory(recipeCategoryId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeStoreTypes.actions.getLastReleasedRecipes](context, count: number): Promise<RecipeInterface[]> {
    try {
      return await koruDb.getModule<RecipeModelInterface>("recipe").getLastReleasedRecipes(count);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeStoreTypes.actions.getNextReleasedRecipes](context, count: number): Promise<RecipeInterface[]> {
    try {
      return await koruDb.getModule<RecipeModelInterface>("recipe").getNextReleasedRecipes(count);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeStoreTypes.actions.getRecipe](context, recipeId: string): Promise<RecipeInterface> {
    try {
      return await koruDb.getModule<RecipeModelInterface>("recipe").getRecipe(recipeId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeStoreTypes.actions.createRecipe](context, recipe: RecipeInterface): Promise<void> {
    try {
      recipe.slugEn = recipe.titleEn != undefined ? slugify(recipe.titleEn) : undefined;
      recipe.slugIt = recipe.titleIt != undefined ? slugify(recipe.titleIt) : undefined;
      recipe.totalTime = recipe.prepTime != undefined && recipe.cookTime != undefined ? recipe.prepTime + recipe.cookTime : undefined;

      setCollectionFields(recipe.id, recipe);
      await koruDb.getModule<RecipeModelInterface>("recipe").createRecipe(recipe);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeStoreTypes.actions.updateRecipe](context, recipe: RecipeInterface): Promise<void> {
    try {
      const oldRecipe: RecipeInterface = await koruDb.getModule<RecipeModelInterface>("recipe").getRecipe(recipe.id);
      if (hasCollectionChanged(recipe, oldRecipe)) throw new Error("sync");

      recipe.slugEn = recipe.titleEn != undefined ? slugify(recipe.titleEn) : undefined;
      recipe.slugIt = recipe.titleIt != undefined ? slugify(recipe.titleIt) : undefined;
      recipe.totalTime = recipe.prepTime != undefined && recipe.cookTime != undefined ? recipe.prepTime + recipe.cookTime : undefined;

      setCollectionFields(recipe.id, recipe);
      await koruDb.getModule<RecipeModelInterface>("recipe").updateRecipe(recipe);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeStoreTypes.actions.deleteRecipe](context, recipeId: string): Promise<void> {
    try {
      await koruDb.getModule<RecipeModelInterface>("recipe").deleteRecipe(recipeId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeStoreTypes.actions.uploadImage](context, file: File): Promise<string> {
    try {
      const extension = file.name.split(".").pop();
      const uniqueName = uniqueId() + "." + extension;

      await koruStorage.upload(koruConfig.storage.folders.recipes + uniqueName, file, (progress: number) => {
        context.commit(recipeStoreTypes.mutations.setUploadProgressValue, progress);
      });
      return uniqueName;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeStoreTypes.actions.deleteImage](context, uniqueName: string): Promise<void> {
    try {
      await koruStorage.delete(koruConfig.storage.folders.recipes + uniqueName);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
