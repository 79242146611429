import { createStoreModule } from "@/core/modules/helpers";
import { KoruStoreModuleInterface } from "@/core/modules/store/models/KoruStoreModule.interface";
import { RecipeStateInterface } from "../models/RecipeState.interface";

import { actions } from "./actions";
import { getters } from "./getters";
import { initialState } from "./initial-state";
import { mutations } from "./mutations";

// eslint-disable-next-line prettier/prettier
export const recipeStore: KoruStoreModuleInterface<RecipeStateInterface> = createStoreModule(
  "recipeState",
  initialState,
  getters,
  actions,
  mutations
);

export const useRecipeStore = (): KoruStoreModuleInterface<RecipeStateInterface> => {
  return recipeStore;
};
