export interface LinkedRoleInterface {
  id: string;
  name: string | undefined;
  readAll: string[];
  readOwn: string[];
  create: string[];
  updateAll: string[];
  updateOwn: string[];
  deleteAll: string[];
  deleteOwn: string[];
  toString: () => string;
}

export function linkedRoleFromFirestore(data: Record<string, unknown>): LinkedRoleInterface {
  return {
    id: data.id as string,
    name: data.name as string,
    readAll: data.readAll as string[],
    readOwn: data.readOwn as string[],
    create: data.create as string[],
    updateAll: data.updateAll as string[],
    updateOwn: data.updateOwn as string[],
    deleteAll: data.deleteAll as string[],
    deleteOwn: data.deleteOwn as string[],
    toString: function (): string {
      return this.id;
    },
  };
}

export function linkedRoleToFirestore(data: LinkedRoleInterface): Record<string, unknown> {
  return {
    id: data.id,
    name: data.name || null,
    readAll: data.readAll,
    readOwn: data.readOwn,
    create: data.create,
    updateAll: data.updateAll,
    updateOwn: data.updateOwn,
    deleteAll: data.deleteAll,
    deleteOwn: data.deleteOwn,
  };
}
