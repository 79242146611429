import { compareAsc } from "date-fns";
import firebase from "firebase";

import { authStoreTypes } from "@/core/modules/auth/store/types";
import { CollectionInterface } from "@/core/modules/database/models/Collection.interface";
import { DatabaseModuleInterface } from "@/core/modules/database/models/DatabaseModule.interface";
import { firebaseFirestore } from "@/core/plugins/firebase";
import { koruDb } from "@/core/modules/database";
import { useAuthStore } from "@/core/modules/auth/store";
import { UserInterface } from "@/core/modules/user/models/User.interface";

export function getReference(collection: string): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
  return firebaseFirestore.collection(collection);
}

export function createFirestoreConverter<T>(
  dataFromFirestore: (data: Record<string, unknown>, id?: string) => T,
  dataToFirestore: (collection: T, id?: string) => Record<string, unknown>
): firebase.firestore.FirestoreDataConverter<T> {
  return {
    fromFirestore: function (
      snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
      options: firebase.firestore.SnapshotOptions | undefined
    ): T {
      const data = snapshot.data(options);
      return dataFromFirestore(data, snapshot.id);
    },
    toFirestore: function (collection: T): Record<string, unknown> {
      return dataToFirestore(collection);
    },
  };
}

export function registerDatabaseModule(module: DatabaseModuleInterface): void {
  if (!(module.name in koruDb.modules)) {
    koruDb.modules[module.name] = module;
  }
}

export function hasCollectionChanged(collection: CollectionInterface, oldCollection: CollectionInterface): boolean {
  return compareAsc(collection.updatedAt, oldCollection.updatedAt) != 0 || collection.updatedBy != oldCollection.updatedBy;
}

export function setCollectionFields(stateId: string, state: CollectionInterface): void {
  const authStore = useAuthStore();
  const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);
  const timestamp = new Date();
  if (stateId == "new") {
    state.createdAt = timestamp;
    state.createdBy = user.id;
    state.updatedAt = timestamp;
    state.updatedBy = user.id;
  } else {
    state.updatedAt = timestamp;
    state.updatedBy = user.id;
  }
}
