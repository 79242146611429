export const itFeaturesLabels = {
  buttons: {
    goToRecipes: "Vai alle Ricette",
  },
  navigation: {
    kitchen: "Cucina",
    pages: "Pagine",
    recipeCategories: "Categorie Ricette",
    recipes: "Ricette",
    website: "Sito",
  },
  roleModules: {
    page: "Pagine",
    recipeCategory: "Ricette - Categorie",
    recipe: "Ricette",
  },
  toast: {},
};
