import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { hasCollectionChanged, setCollectionFields } from "@/core/modules/helpers";
import { koruAuth } from "@/core/modules/auth";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { NotificationInterface, createNewNotification } from "@/core/modules/notification/models/Notification.interface";
import { NotificationModelInterface } from "@/core/modules/database/models/NotificationModel.interface";
import { UserInterface } from "../models/User.interface";
import { UserModelInterface } from "@/core/modules/database/models/UserModel.interface";
import { userStoreTypes } from "./types";

import { checkUserDeleteConstraints } from "@/features/modules/helpers";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [userStoreTypes.actions.getUsers](): Promise<UserInterface[]> {
    try {
      return await koruDb.getModule<UserModelInterface>("user").getUsers();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [userStoreTypes.actions.getUsersByRole](context, roleId: string): Promise<UserInterface[]> {
    try {
      return await koruDb.getModule<UserModelInterface>("user").getUsersByRole(roleId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [userStoreTypes.actions.getUser](context, userId: string): Promise<UserInterface> {
    try {
      return await koruDb.getModule<UserModelInterface>("user").getUser(userId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [userStoreTypes.actions.createUser](context, user: UserInterface): Promise<void> {
    try {
      setCollectionFields(user.id, user);
      user.id = await koruAuth.registerUser(user);
      await koruDb.getModule<UserModelInterface>("user").createUser(user);
      // TODO
      const userNotification: NotificationInterface = createNewNotification();
      userNotification.type = "userCreated";
      userNotification.detail = user.id;
      await koruDb.getModule<NotificationModelInterface>("notification").createNotification(userNotification);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [userStoreTypes.actions.updateUser](context, user: UserInterface): Promise<void> {
    try {
      const oldUser: UserInterface = await koruDb.getModule<UserModelInterface>("user").getUser(user.id);
      if (hasCollectionChanged(user, oldUser)) throw new Error("sync");

      if (oldUser.email != user.email) await koruAuth.updateUser(user);

      setCollectionFields(user.id, user);
      await koruDb.getModule<UserModelInterface>("user").updateUser(user);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [userStoreTypes.actions.toggleUser](context, user: UserInterface): Promise<void> {
    try {
      if (user.blocked == false) {
        user.blocked = true;
        await koruAuth.blockUser(user.id);
      } else {
        user.blocked = false;
        await koruAuth.unblockUser(user.id);
      }
      setCollectionFields(user.id, user);
      await koruDb.getModule<UserModelInterface>("user").updateUser(user);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [userStoreTypes.actions.deleteUser](context, userId: string): Promise<void> {
    try {
      const checkResult = await checkUserDeleteConstraints(userId);
      if (checkResult != undefined) throw new Error(checkResult);

      await koruAuth.deleteUser(userId);
      await koruDb.getModule<UserModelInterface>("user").deleteUser(userId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [userStoreTypes.actions.isEmailUnique](context, { email, oldEmail }): Promise<number> {
    try {
      return await koruDb.getModule<UserModelInterface>("user").getUserCountByEmail(email, oldEmail);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
