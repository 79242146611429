import { ActionTree } from "vuex";
import { uniqueId } from "@/core/plugins/unique-id";

import { RecipeCategoryInterface } from "../models/RecipeCategory.interface";
import { RecipeCategoryModelInterface } from "@/features/modules/database/models/RecipeCategoryModel.interface";
import { recipeCategoryStoreTypes } from "./types";
import { koruConfig } from "@/core/modules/config";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { koruStorage } from "@/core/modules/storage";
import { RecipeCategoryStateInterface } from "../models/RecipeCategoryState.interface";
import { RecipeInterface } from "@/features/modules/recipe/models/Recipe.interface";
import { recipeStore } from "@/features/modules/recipe/store";
import { recipeStoreTypes } from "@/features/modules/recipe/store/types";

import { hasCollectionChanged, setCollectionFields, slugify } from "@/core/modules/helpers";

export const actions: ActionTree<RecipeCategoryStateInterface, KoruStateInterface> = {
  async [recipeCategoryStoreTypes.actions.getRecipeCategories](): Promise<RecipeCategoryInterface[]> {
    try {
      return await koruDb.getModule<RecipeCategoryModelInterface>("recipeCategory").getRecipeCategories();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeCategoryStoreTypes.actions.getRecipeCategory](context, recipeCategoryId: string): Promise<RecipeCategoryInterface> {
    try {
      return await koruDb.getModule<RecipeCategoryModelInterface>("recipeCategory").getRecipeCategory(recipeCategoryId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeCategoryStoreTypes.actions.createRecipeCategory](context, recipeCategory: RecipeCategoryInterface): Promise<void> {
    try {
      recipeCategory.slugEn = recipeCategory.titleEn != undefined ? slugify(recipeCategory.titleEn) : undefined;
      recipeCategory.slugIt = recipeCategory.titleIt != undefined ? slugify(recipeCategory.titleIt) : undefined;

      setCollectionFields(recipeCategory.id, recipeCategory);
      await koruDb.getModule<RecipeCategoryModelInterface>("recipeCategory").createRecipeCategory(recipeCategory);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeCategoryStoreTypes.actions.updateRecipeCategory](context, recipeCategory: RecipeCategoryInterface): Promise<void> {
    try {
      const oldRecipeCategory: RecipeCategoryInterface = await koruDb
        .getModule<RecipeCategoryModelInterface>("recipeCategory")
        .getRecipeCategory(recipeCategory.id);
      if (hasCollectionChanged(recipeCategory, oldRecipeCategory)) throw new Error("sync");

      recipeCategory.slugEn = recipeCategory.titleEn != undefined ? slugify(recipeCategory.titleEn) : undefined;
      recipeCategory.slugIt = recipeCategory.titleIt != undefined ? slugify(recipeCategory.titleIt) : undefined;

      setCollectionFields(recipeCategory.id, recipeCategory);
      await koruDb.getModule<RecipeCategoryModelInterface>("recipeCategory").updateRecipeCategory(recipeCategory);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeCategoryStoreTypes.actions.deleteRecipeCategory](context, recipeCategoryId: string): Promise<void> {
    try {
      const recipes = (await recipeStore.action(recipeStoreTypes.actions.getRecipesByCategory, recipeCategoryId)) as RecipeInterface[];
      if (recipes.length > 0) throw new Error("children");

      await koruDb.getModule<RecipeCategoryModelInterface>("recipeCategory").deleteRecipeCategory(recipeCategoryId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeStoreTypes.actions.uploadImage](context, file: File): Promise<string> {
    try {
      const extension = file.name.split(".").pop();
      const uniqueName = uniqueId() + "." + extension;

      await koruStorage.upload(koruConfig.storage.folders.categories + uniqueName, file, (progress: number) => {
        context.commit(recipeStoreTypes.mutations.setUploadProgressValue, progress);
      });
      return uniqueName;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [recipeStoreTypes.actions.deleteImage](context, uniqueName: string): Promise<void> {
    try {
      await koruStorage.delete(koruConfig.storage.folders.categories + uniqueName);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
