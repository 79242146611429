import { RouteRecordRaw } from "vue-router";

export const workbenchRoutes: Array<RouteRecordRaw> = [
  {
    path: "/workbench",
    name: "Workbench",
    component: () => import("../views/Workbench.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "workbench",
      requiredRights: ["readAll"],
    },
  },
];
