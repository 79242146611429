import { RouteRecordRaw } from "vue-router";
import CodiceFiscale from "codice-fiscale-js";

import { getObjectValueWithField } from ".";
import { koruConfig } from "@/core/modules/config";
import { koruRouter } from "@/core/modules/router";
import { UserInterface } from "@/core/modules/user/models/User.interface";

export function registerRoutes(routes: Array<RouteRecordRaw>): void {
  routes.forEach((route: RouteRecordRaw) => koruRouter.addRoute(route));
}

export function userHasReadRight(user: UserInterface, right: string): boolean {
  if (right == "any" || (user.role?.readAll as Array<string>).includes(right) || (user.role?.readOwn as Array<string>).includes(right)) {
    return true;
  }
  return false;
}

export function userHasRightWithType(user: UserInterface, module: string, rights: Array<string>): boolean {
  if (rights.length <= 0) {
    return true;
  }

  let permission = false;
  rights.forEach((right: string) => {
    const allowedModules = getObjectValueWithField(user.role, right) as Array<string>;
    if (allowedModules.includes(module)) {
      permission = true;
    }
    return;
  });

  return permission;
}

export function createRange(from: number, to: number, step = 1): number[] {
  const array: number[] = [];
  for (let i = from; i <= to; i = i + step) {
    array.push(i);
  }
  return array;
}

export function resetLocalStorageIfNeeded(): void {
  if (!localStorage.getItem("koruVersion")) {
    localStorage.clear();
    localStorage.setItem("koruVersion", koruConfig.app.version);
  }

  const currentAppVersion: string = localStorage.getItem("koruVersion") as string;

  console.log("KORU Version", currentAppVersion);

  if (currentAppVersion !== koruConfig.app.version) {
    localStorage.clear();
    localStorage.setItem("koruVersion", koruConfig.app.version);
  }
}

export function goToErrorPage(error: string): void {
  localStorage.setItem("lastError", error);
  koruRouter.push("/error");
}

export function calculateCF(
  firstName: string | undefined,
  lastName: string | undefined,
  sex: string | undefined,
  birthDate: Date | undefined,
  birthPlace: string | undefined
): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    if (firstName == undefined || lastName == undefined || sex == undefined || birthDate == undefined || birthPlace == undefined) {
      reject();
      return;
    }
    const cf = new CodiceFiscale({
      name: firstName,
      surname: lastName,
      gender: sex,
      day: birthDate.getDate(),
      month: birthDate.getMonth() == 12 ? 0 : birthDate.getMonth() + 1,
      year: birthDate.getFullYear(),
      birthplace: birthPlace,
    });
    resolve(cf.cf);
  });
}

export function simpleCellSave(event: Record<string, unknown>): void {
  const { data, newValue, field } = event;

  (data as Record<string, unknown>)[field as string] = newValue;
}
