import firebase from "firebase/app";

export interface IImportOptions {
  dates?: string[];
  autoParseDates?: boolean;
  geos?: string[];
  autoParseGeos?: boolean;
  refs?: string[];
  showLogs?: boolean;
}

export interface IExportOptions {
  docsFromEachCollection?: number;
  refs?: string[];
  queryCollection?: (
    ref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
  ) => Promise<firebase.firestore.QuerySnapshot<unknown>>;
}

export const makeGeoPoint = (geoValues: { latitude: number; longitude: number }): firebase.firestore.GeoPoint | null => {
  if (!geoValues.latitude || !geoValues.longitude) return null;
  return new firebase.firestore.GeoPoint(geoValues.latitude, geoValues.longitude);
};

export const makeTime = (firebaseTimestamp: { seconds: number; nanoseconds: number }): Date | null => {
  if (!firebaseTimestamp || !firebaseTimestamp.seconds) return null;
  return new Date(firebaseTimestamp.seconds * 1000);
};

export const getPath = (obj?: { path?: string }): unknown => {
  if (obj && typeof obj.path === "string") {
    return obj.path;
  }
  return obj;
};

const isObject = (test: unknown) => {
  return typeof test === "object";
};

const isArray = (test: unknown) => {
  return Array.isArray(test);
};

export const traverseObjects = (data: Record<string, unknown>, callback: (value: unknown) => void): void => {
  for (const [key, value] of Object.entries(data)) {
    if (!isObject(value) && !isArray(value) && (value as Record<string, unknown>).constructor?.name !== "DocumentReference") {
      continue;
    }

    const checkResult: unknown = callback(value);
    if (checkResult) {
      data[key] = checkResult;
      continue;
    }

    traverseObjects(data[key] as Record<string, unknown>, callback);
  }
};

export const parseAndConvertDates = (document: Record<string, unknown>): void => {
  Object.keys(document).forEach((key: string) => {
    const value: unknown = document[key];
    const isTimeStamp =
      value != null &&
      typeof value === "object" &&
      Object.prototype.hasOwnProperty.call(value, "seconds") &&
      Object.prototype.hasOwnProperty.call(value, "nanoseconds");
    if (isTimeStamp) {
      document[key] = makeTime(value as { seconds: number; nanoseconds: number });
    }
  });
};

export const parseAndConvertGeos = (document: Record<string, unknown>): void => {
  Object.keys(document).forEach((key: string) => {
    const value: unknown = document[key];
    const isGeoPoint =
      value != null &&
      typeof value === "object" &&
      Object.prototype.hasOwnProperty.call(value, "latitude") &&
      Object.prototype.hasOwnProperty.call(value, "longitude");
    if (isGeoPoint) {
      document[key] = makeGeoPoint(value as { latitude: number; longitude: number });
    }
  });
};
