import { RouteRecordRaw } from "vue-router";

export const pageRoutes: Array<RouteRecordRaw> = [
  {
    path: "/pages",
    name: "PageList",
    component: () => import("../views/PageList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "page",
      requiredRights: ["readAll", "readOwn"],
    },
  },
  {
    path: "/pages/:id",
    name: "PageEdit",
    component: () => import("../views/PageEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "page",
      requiredRights: ["readAll", "readOwn", "updateAll", "updateOwn"],
    },
  },
];
