import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { koruDb } from "@/core/modules/database";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { NotificationInterface } from "../models/Notification.interface";
import { NotificationModelInterface } from "@/core/modules/database/models/NotificationModel.interface";
import { notificationStoreTypes } from "./types";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [notificationStoreTypes.actions.getNotifications](): Promise<NotificationInterface[]> {
    try {
      return await koruDb.getModule<NotificationModelInterface>("notification").getNotifications();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [notificationStoreTypes.actions.getNotificationsByType](context, type: string): Promise<NotificationInterface[]> {
    try {
      return await koruDb.getModule<NotificationModelInterface>("notification").getNotificationsByType(type);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  [notificationStoreTypes.actions.getNotificationsMatrix](context, notifications: NotificationInterface[]): Record<string, number> {
    try {
      const notificationsMatrix: Record<string, number> = {};
      for (const notification of notifications) {
        if (notification.type != undefined) {
          if (Object.keys(notifications).indexOf(notification.type) >= 0) {
            notificationsMatrix[notification.type]++;
          } else {
            notificationsMatrix[notification.type] = 1;
          }
        }
      }
      return notificationsMatrix;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [notificationStoreTypes.actions.getNotification](context, notificationId: string): Promise<NotificationInterface> {
    try {
      return await koruDb.getModule<NotificationModelInterface>("notification").getNotification(notificationId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [notificationStoreTypes.actions.createNotification](context, notification: NotificationInterface): Promise<void> {
    try {
      await koruDb.getModule<NotificationModelInterface>("notification").createNotification(notification, true);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [notificationStoreTypes.actions.updateNotification](context, notification: NotificationInterface): Promise<void> {
    try {
      await koruDb.getModule<NotificationModelInterface>("notification").updateNotification(notification);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [notificationStoreTypes.actions.deleteNotification](context, notificationId: string): Promise<void> {
    try {
      await koruDb.getModule<NotificationModelInterface>("notification").deleteNotification(notificationId);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
