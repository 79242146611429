import firebase from "firebase";
import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/models/EmptyState.interface";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { importBackup } from "@/core/plugins/import-export";
import { installerStoreTypes } from "./types";
import { UserInterface, userConverter } from "@/core/modules/user/models/User.interface";

import { getReference } from "@/core/modules/helpers";

import installerData from "@/data/installer-data.json";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  async [installerStoreTypes.actions.checkInstallStatus](): Promise<string> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<UserInterface> = await getReference("users").withConverter(userConverter).get();

      if (snapshot.size > 0) {
        return "installed";
      }
      return "installNeeded";
    } catch (error: unknown) {
      throw new Error((error as Error).message);
      return "error";
    }
  },
  async [installerStoreTypes.actions.install](): Promise<void> {
    try {
      const options = { autoParseDates: true, autoParseGeos: false, showLogs: true };
      await importBackup(installerData, options);
      console.log("Install!");
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
