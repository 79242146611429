import { firebaseFirestore } from "@/core/plugins/firebase";
import { IImportOptions, makeGeoPoint, makeTime, parseAndConvertDates, parseAndConvertGeos } from "./helpers";

export const importBackup = (data: Record<string, unknown>, options: IImportOptions): Promise<{ status: boolean; message: string }> => {
  return new Promise<{ status: boolean; message: string }>((resolve, reject) => {
    if (typeof data === "object") {
      const dataObj = data;

      updateCollection(dataObj, options)
        .then(() => {
          resolve({
            status: true,
            message: "Collection successfully imported!",
          });
        })
        .catch((error) => {
          reject({ status: false, message: error.message });
        });
    } else {
      reject({ status: false, message: "Invalid data" });
    }
  });
};

const updateCollection = async (importObject: Record<string, unknown>, options: IImportOptions = {}) => {
  Object.keys(importObject).forEach((collectionName: string) => {
    console.log(`Processing collection ${collectionName}`);

    const documents: Record<string, unknown> = importObject[collectionName] as Record<string, unknown>;

    // TODO ?
    Object.keys(documents).forEach(async (documentId: string) => {
      const document: Record<string, unknown> = documents[documentId] as Record<string, unknown>;

      await startUpdating(collectionName, documentId, document, options);
    });
  });
};

const startUpdating = (collectionName: string, documentId: string, document: Record<string, unknown>, options: IImportOptions) => {
  // Update date value
  if (options.dates && options.dates.length > 0) {
    options.dates.forEach((date) => {
      if (Object.prototype.hasOwnProperty.call(document, date)) {
        // check type of the date
        if (Array.isArray(document[date])) {
          document[date] = (document[date] as { seconds: number; nanoseconds: number }[]).map((d) => makeTime(d));
        } else {
          document[date] = makeTime(document[date] as { seconds: number; nanoseconds: number });
        }
      }
    });
  }

  if (options.autoParseDates) {
    parseAndConvertDates(document);
  }

  if (options.geos && options.geos.length > 0) {
    options.geos.forEach((geo) => {
      if (Object.prototype.hasOwnProperty.call(document, geo)) {
        // array of geo locations
        if (Array.isArray(document[geo])) {
          document[geo] = (document[geo] as { latitude: number; longitude: number }[]).map((geoValues) => makeGeoPoint(geoValues));
        } else {
          document[geo] = makeGeoPoint(document[geo] as { latitude: number; longitude: number });
        }
      }
    });
  }

  if (options.autoParseGeos) {
    parseAndConvertGeos(document);
  }

  return new Promise((resolve, reject) => {
    firebaseFirestore
      .collection(collectionName)
      .doc(documentId)
      .set(document)
      .then(() => {
        options?.showLogs && console.log(`${documentId} was successfully added to firestore!`);
        resolve({
          status: true,
          message: `${documentId} was successfully added to firestore!`,
        });
      })
      .catch((error) => {
        console.log(error);
        reject({
          status: false,
          message: error.message,
        });
      });
  });
};
