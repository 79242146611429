
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { usePrimeVue } from "primevue/config";

import { authStoreTypes } from "@/core/modules/auth/store/types";
import { LocaleInterface } from "../models/Locale.interface";
import { localeStoreTypes } from "../store/types";
import { useAuthStore } from "@/core/modules/auth/store";
import { useLocaleStore } from "../store";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { useKoruLocale } from "../";

import { enPrimevue } from "@/core/locales/primevue/en.ts";
import { itPrimevue } from "@/core/locales/primevue/it.ts";

export default defineComponent({
  name: "LocaleSelector",
  setup() {
    const { t } = useKoruLocale();
    const authStore = useAuthStore();
    const localeStore = useLocaleStore();
    const primeVue = usePrimeVue();
    const useLocale = useI18n();
    const selectedLocale = ref();
    const locales: LocaleInterface[] = localeStore.getter(localeStoreTypes.getters.getLocales);

    const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);
    locales.forEach((locale: LocaleInterface) => {
      if (locale.name == user.detail.locale) {
        selectedLocale.value = locale;
        return;
      }
    });

    return {
      locales,
      localeStore,
      primeVue,
      selectedLocale,
      t,
      useLocale,
    };
  },
  mounted() {
    this.selectLocale(this.selectedLocale);
  },
  methods: {
    selectLocale(locale: LocaleInterface): void {
      this.selectedLocale = locale;
      this.localeStore.action(localeStoreTypes.actions.setSelectedLocale, locale);
      this.useLocale.locale.value = locale.name;
      if (locale.name == "en") {
        this.primeVue.config.locale = enPrimevue;
      } else {
        this.primeVue.config.locale = itPrimevue;
      }
    },
  },
});
