import { LocaleMessageValue, RemoveIndexSignature } from "@intlify/core-base";
import { ComposerTranslation, VueMessageType } from "vue-i18n";

import { koruApp } from "@/main";
import { koruConfig } from "@/core/modules/config";

export function showToastWithSuccessType(
  success: string,
  t?: ComposerTranslation<unknown, unknown, RemoveIndexSignature<{ [x: string]: LocaleMessageValue<VueMessageType> }>, never, never, never>
): void {
  const toast = koruApp.config.globalProperties.$toast;

  let successLabel = "";
  if (t != undefined) {
    if (t("toast.success." + success) != undefined && t("toast.success." + success) != "toast.success." + success) {
      successLabel = t("toast.success." + success);
    } else {
      successLabel = t("toast.success.generic");
    }
  } else {
    successLabel = success;
  }

  toast.add({
    severity: "success",
    summary: t != undefined ? t("toast.success.title") : "toast.success.title",
    detail: successLabel,
    group: "bedrock",
    life: koruConfig.layout.toastLife,
  });
}

export function showToastWithErrorType(
  error: string,
  fallbackError: string | undefined = undefined,
  t?: ComposerTranslation<unknown, unknown, RemoveIndexSignature<{ [x: string]: LocaleMessageValue<VueMessageType> }>, never, never, never>
): void {
  const toast = koruApp.config.globalProperties.$toast;

  let errorLabel = "";
  if (t != undefined) {
    if (t("toast.error." + error) != undefined && t("toast.error." + error) != "toast.error." + error) {
      errorLabel = t("toast.error." + error);
    } else if (fallbackError != undefined) {
      if (t("toast.error." + fallbackError) != undefined && t("toast.error." + fallbackError) != "toast.error." + fallbackError) {
        errorLabel = t("toast.error." + fallbackError);
      } else {
        errorLabel = t("toast.error.generic");
      }
    } else {
      errorLabel = t("toast.error.generic");
    }
  } else {
    errorLabel = error;
  }

  toast.add({
    severity: "error",
    summary: t != undefined ? t("toast.error.title") : "toast.error.title",
    detail: errorLabel,
    group: "bedrock",
    life: koruConfig.layout.toastLife,
  });
}

export function showToastWithWarningType(
  warning: string,
  t?: ComposerTranslation<unknown, unknown, RemoveIndexSignature<{ [x: string]: LocaleMessageValue<VueMessageType> }>, never, never, never>
): void {
  const toast = koruApp.config.globalProperties.$toast;

  let warningLabel = "";
  if (t != undefined) {
    if (t("toast.warning." + warning) != "toast.warning." + warning) {
      warningLabel = t("toast.warning." + warning);
    } else {
      warningLabel = t("toast.warning.generic");
    }
  } else {
    warningLabel = warning;
  }

  toast.add({
    severity: "warn",
    summary: t != undefined ? t("toast.warning.title") : "toast.warning.title",
    detail: warningLabel,
    group: "bedrock",
    life: koruConfig.layout.toastLife,
  });
}
