import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/messaging";
import "firebase/storage";

import { koruConfig } from "@/core/modules/config";

const firebaseConfig = {
  apiKey: koruConfig.firebase.apiKey,
  authDomain: koruConfig.firebase.authDomain,
  databaseURL: koruConfig.firebase.databaseURL,
  projectId: koruConfig.firebase.projectId,
  storageBucket: koruConfig.firebase.storageBucket,
  messagingSenderId: koruConfig.firebase.messagingSenderId,
  appId: koruConfig.firebase.appId,
};

firebase.initializeApp(firebaseConfig);
firebase.auth();
firebase.firestore();

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export const firebaseAuth = firebase.auth();
export const firebaseFirestore = firebase.firestore();
export const firebaseFunctions = firebase.functions();
export const firebaseMessaging = firebase.messaging();
export const firebaseStorage = firebase.storage();
