import {
  CollectionInterface,
  createCollection,
  collectionFromFirestore,
  collectionToFirestore,
} from "@/core/modules/database/models/Collection.interface";

import { createFirestoreConverter } from "@/core/modules/helpers";

export interface RoleInterface extends CollectionInterface {
  name: string | undefined;
  readAll: string[];
  readOwn: string[];
  create: string[];
  updateAll: string[];
  updateOwn: string[];
  deleteAll: string[];
  deleteOwn: string[];
}

export const createNewRole = (): RoleInterface => {
  return createCollection<RoleInterface>({
    name: undefined,
    readAll: [],
    readOwn: [],
    create: [],
    updateAll: [],
    updateOwn: [],
    deleteAll: [],
    deleteOwn: [],
  });
};

export function roleFromFirestore(data: Record<string, unknown>, id?: string): RoleInterface {
  return collectionFromFirestore<RoleInterface>(
    id != undefined ? id : (data.id as string),
    {
      name: data.name || undefined,
      readAll: data.readAll || [],
      readOwn: data.readOwn || [],
      create: data.create || [],
      updateAll: data.updateAll || [],
      updateOwn: data.updateOwn || [],
      deleteAll: data.deleteAll || [],
      deleteOwn: data.deleteOwn || [],
    },
    data
  );
}

export function roleToFirestore(role: RoleInterface, id?: string): Record<string, unknown> {
  return collectionToFirestore(
    Object.assign(id != undefined ? { id: id } : {}, {
      name: role.name || null,
      readAll: role.readAll || [],
      readOwn: role.readOwn || [],
      create: role.create || [],
      updateAll: role.updateAll || [],
      updateOwn: role.updateOwn || [],
      deleteAll: role.deleteAll || [],
      deleteOwn: role.deleteOwn || [],
    }),
    role
  );
}
export const roleConverter = createFirestoreConverter(roleFromFirestore, roleToFirestore);
