import { createStoreModule } from "@/core/modules/helpers";
import { KoruStoreModuleInterface } from "@/core/modules/store/models/KoruStoreModule.interface";
import { RecipeCategoryStateInterface } from "../models/RecipeCategoryState.interface";

import { actions } from "./actions";
import { getters } from "./getters";
import { initialState } from "./initial-state";
import { mutations } from "./mutations";

// eslint-disable-next-line prettier/prettier
export const recipeCategoryStore: KoruStoreModuleInterface<RecipeCategoryStateInterface> = createStoreModule(
  "recipeCategoryState",
  initialState,
  getters,
  actions,
  mutations
);

export const useRecipeCategoryStore = (): KoruStoreModuleInterface<RecipeCategoryStateInterface> => {
  return recipeCategoryStore;
};
