import { GetterTree } from "vuex";

import { AuthStateInterface } from "../models/AuthState.interface";
import { authStoreTypes } from "./types";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { UserInterface } from "@/core/modules/user/models/User.interface";

export const getters: GetterTree<AuthStateInterface, KoruStateInterface> = {
  [authStoreTypes.getters.isLoggedIn]: (state: AuthStateInterface): boolean => {
    return state.isLoggedIn;
  },
  [authStoreTypes.getters.getUser]: (state: AuthStateInterface): UserInterface | undefined => {
    return state.user;
  },
  [authStoreTypes.getters.isLoading]: (state: AuthStateInterface): boolean => {
    return state.loading;
  },
};
