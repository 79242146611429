import firebase from "firebase";

import { firebaseFirestore } from "@/core/plugins/firebase";
import { LinkedUserInterface } from "@/core/modules/user/models/LinkedUser.interface";
import { UserInterface, userConverter } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "../models/UserModel.interface";
import { setUserCascadeUpdates } from "@/features/modules/helpers";

import { getReference } from "@/core/modules/helpers";

export const userModel: UserModelInterface = {
  name: "user",

  async getUsers(): Promise<UserInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<UserInterface> = await getReference("users")
        .withConverter(userConverter)
        .orderBy("lastName")
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: UserInterface[] = [];
      snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<UserInterface>) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getUsersByRole(roleId: string): Promise<UserInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<UserInterface> = await getReference("users")
        .withConverter(userConverter)
        .where("role.id", "==", roleId)
        .orderBy("lastName")
        .orderBy("firstName")
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: UserInterface[] = [];
      snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<UserInterface>) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getUser(userId: string): Promise<UserInterface> {
    try {
      // eslint-disable-next-line prettier/prettier
      const doc: firebase.firestore.DocumentSnapshot<UserInterface> = await getReference("users")
        .withConverter(userConverter)
        .doc(userId)
        .get();

      if (doc.exists) {
        return doc.data() as UserInterface;
      } else {
        throw new Error(`User #${userId} not found`);
      }
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getUserCountByEmail(email: string, oldEmail: string): Promise<number> {
    try {
      if (email == undefined) return 0;
      if (oldEmail == undefined) oldEmail = "nil";
      const snapshot: firebase.firestore.QuerySnapshot<UserInterface> = await getReference("users")
        .withConverter(userConverter)
        .where("email", "==", email)
        .where("email", "!=", oldEmail)
        .get();

      return snapshot.size;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async createUser(user: UserInterface, forceId = false): Promise<void> {
    try {
      if (forceId) {
        // eslint-disable-next-line prettier/prettier
        await getReference("users")
          .withConverter(userConverter)
          .doc(user.id)
          .set(user);
      } else {
        // eslint-disable-next-line prettier/prettier
        await getReference("users")
          .withConverter(userConverter)
          .doc()
          .set(user);
      }
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async updateUser(user: UserInterface): Promise<void> {
    try {
      const batch = firebaseFirestore.batch();

      batch.set(getReference("users").withConverter(userConverter).doc(user.id), user);

      const linkedUser: LinkedUserInterface = {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      };

      await setUserCascadeUpdates(batch, linkedUser);

      await batch.commit();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async deleteUser(userId: string): Promise<void> {
    try {
      // eslint-disable-next-line prettier/prettier
      await getReference("users")
        .doc(userId)
        .delete();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
