export const recipeStoreTypes = {
  getters: {
    getUploadProgressValue: "GET_UPLOAD_PROGRESS_VALUE",
    isLoading: "IS_LOADING",
    isUploading: "IS_UPLOADING",
  },
  actions: {
    getRecipes: "GET_RECIPES",
    getRecipesByCategory: "GET_RECIPES_BY_CATEGORY",
    getLastReleasedRecipes: "GET_LAST_RELEASED_RECIPES",
    getNextReleasedRecipes: "GET_NEXT_RELEASED_RECIPES",
    getRecipe: "GET_RECIPE",
    createRecipe: "CREATE_RECIPE",
    updateRecipe: "UPDATE_RECIPE",
    deleteRecipe: "DELETE_RECIPE",
    uploadImage: "UPLOAD_IMAGE",
    deleteImage: "DELETE_IMAGE",
  },
  mutations: {
    setUploadProgressValue: "SET_UPLOAD_PROGRESS_VALUE",
    uploadStart: "UPLOAD_START",
    uploadStop: "UPLOAD_STOP",
  },
};
