
import { computed, defineComponent, onBeforeUnmount, onMounted, Ref, ref } from "vue";

import { NotificationInterface } from "../models/Notification.interface";
import { notificationStoreTypes } from "../store/types";
import { NotificationTypeInterface } from "../models/NotificationTypeInterface";
import { useKoruConfig } from "@/core/modules/config";
import { useKoruLocale } from "@/core/modules/locale";
import { useNotificationStore } from "../store";

export default defineComponent({
  name: "NotificationMenu",
  props: {
    topbarNotificationMenuActive: Boolean,
  },
  emits: ["topbar-notification"],
  setup() {
    const { t } = useKoruLocale();
    const koruConfig = useKoruConfig();
    const notificationTypes: NotificationTypeInterface[] = koruConfig.notification.types;

    const notificationStore = useNotificationStore();
    const notifications: Ref<NotificationInterface[]> = ref([]);
    const notificationsMatrix: Ref<Record<string, unknown>> = ref({});

    const notificationsCount = computed(() => {
      return notifications.value.length;
    });

    let notificationTimer: number;

    onMounted(() => {
      startNotificationTimer();
    });

    onBeforeUnmount(() => {
      stopNotificationTimer();
    });

    const startNotificationTimer = () => {
      checkNotifications();
      notificationTimer = window.setInterval(checkNotifications, koruConfig.notification.interval);
    };

    const stopNotificationTimer = () => {
      if (notificationTimer) {
        clearInterval(notificationTimer);
      }
    };

    const checkNotifications = async () => {
      notifications.value = (await notificationStore.action(notificationStoreTypes.actions.getNotifications)) as NotificationInterface[];
      notificationsMatrix.value = (await notificationStore.action(
        notificationStoreTypes.actions.getNotificationsMatrix,
        notifications.value
      )) as Record<string, unknown>;
    };

    return {
      notificationsMatrix,
      notificationsCount,
      notificationTypes,
      t,
    };
  },
  methods: {
    onTopbarNotificationMenuButtonClick(event: Event): void {
      this.$emit("topbar-notification", event);
    },
  },
  /*
    TODO
    firebaseMessaging
      .getToken({
        vapidKey: "BP_b90t_5rWZS-HehUHUx6m88E53mVEbfhztw8uMDSa9s0dJc-CzVFkitxEx2FBt6JLd84nDXRSU3wXRKNXZs10",
      })
      .then((currentToken: string) => {
        if (currentToken) {
          console.log("Token: ", currentToken);
          // Send the token to your server and update the UI if necessary
        } else {
          // Show permission request UI
          console.log("No registration token available. Request permission to generate one.");
        }
      })
      .catch((err: unknown) => {
        console.log("An error occurred while retrieving token.", err);
      });

    firebaseMessaging.onMessage((payload) => {
      alert(payload.notification.body);
      console.log("Message received foreground.", payload);
    });
  */
  /*
    firebaseMessaging.onBackgroundMessage((payload) => {
      console.log("[firebase-messaging-sw.js] Received background message ", payload);
      // Customize notification here
      const notificationTitle = "Background Message Title";
      const notificationOptions = {
        body: "Background Message body.",
        icon: "/firebase-logo.png",
      };
      self.registration.showNotification(notificationTitle, notificationOptions);
    });
  */
});
