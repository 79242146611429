import firebase from "firebase";

import { NotificationInterface, notificationConverter } from "@/core/modules/notification/models/Notification.interface";
import { NotificationModelInterface } from "../models/NotificationModel.interface";

import { getReference } from "@/core/modules/helpers";

export const notificationModel: NotificationModelInterface = {
  name: "notification",

  async getNotifications(): Promise<NotificationInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<NotificationInterface> = await getReference("notifications")
        .withConverter(notificationConverter)
        .orderBy("createdAt", "desc")
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: NotificationInterface[] = [];
      snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<NotificationInterface>) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getNotificationsByType(type: string): Promise<NotificationInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<NotificationInterface> = await getReference("notifications")
        .withConverter(notificationConverter)
        .where("type", "==", type)
        .orderBy("createdAt", "desc")
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: NotificationInterface[] = [];
      snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<NotificationInterface>) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getNotification(notificationId: string): Promise<NotificationInterface> {
    try {
      // eslint-disable-next-line prettier/prettier
      const doc: firebase.firestore.DocumentSnapshot<NotificationInterface> = await getReference("notifications")
        .withConverter(notificationConverter)
        .doc(notificationId)
        .get();

      if (doc.exists) {
        return doc.data() as NotificationInterface;
      } else {
        throw new Error(`Notification #${notificationId} not found`);
      }
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async createNotification(notification: NotificationInterface, forceId = false): Promise<void> {
    try {
      if (forceId) {
        // eslint-disable-next-line prettier/prettier
        await getReference("notifications")
          .withConverter(notificationConverter)
          .doc(notification.id)
          .set(notification);
      } else {
        // eslint-disable-next-line prettier/prettier
        await getReference("notifications")
          .withConverter(notificationConverter)
          .doc()
          .set(notification);
      }
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async updateNotification(notification: NotificationInterface): Promise<void> {
    try {
      // eslint-disable-next-line prettier/prettier
      await getReference("notifications")
        .withConverter(notificationConverter)
        .doc(notification.id)
        .set(notification);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async deleteNotification(notificationId: string): Promise<void> {
    try {
      // eslint-disable-next-line prettier/prettier
      await getReference("notifications")
        .doc(notificationId)
        .delete();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
