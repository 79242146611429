export const roleStoreTypes = {
  actions: {
    getRoles: "GET_ROLES",
    getRole: "GET_ROLE",
    createRole: "CREATE_ROLE",
    updateRole: "UPDATE_ROLE",
    deleteRole: "DELETE_ROLE",
    fromValuesToMatrix: "FROM_VALUES_TO_MATRIX",
    fromMatrixToValues: "FROM_MATRIX_TO_VALUES",
  },
};
