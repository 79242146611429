export const koruStoreTypes = {
  getters: {
    isEditing: "IS_EDITING",
    isLoading: "IS_LOADING",
    getColorScheme: "GET_COLOR_SCHEME",
    showEditingDialog: "SHOW_EDITING_DIALOG",
  },
  actions: {
    navigateTo: "NAVIGATE_TO",
    navigateWithConfirm: "NAVIGATE_WITH_CONFIRM",
  },
  mutations: {
    closeEditingDialog: "CLOSE_EDITING_DIALOG",
    editingStart: "EDITING_START",
    editingStop: "EDITING_STOP",
    loadingStart: "LOADING_START",
    loadingStop: "LOADING_STOP",
    openEditingDialog: "OPEN_EDITING_DIALOG",
  },
};
