import firebase from "firebase";

import { firebaseFirestore } from "@/core/plugins/firebase";
import { koruDb } from "../module";
import { RoleInterface, roleConverter } from "@/core/modules/role/models/Role.interface";
import { RoleModelInterface } from "../models/RoleModel.interface";
import { linkedRoleToFirestore } from "@/core/modules/role/models/LinkedRole.interface";
import { UserInterface } from "@/core/modules/user/models/User.interface";
import { UserModelInterface } from "../models/UserModel.interface";

import { getReference } from "@/core/modules/helpers";

export const roleModel: RoleModelInterface = {
  name: "role",

  async getRoles(): Promise<RoleInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<RoleInterface> = await getReference("roles")
        .withConverter(roleConverter)
        .orderBy("name")
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: RoleInterface[] = [];
      snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<RoleInterface>) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getRole(roleId: string): Promise<RoleInterface> {
    try {
      // eslint-disable-next-line prettier/prettier
      const doc: firebase.firestore.DocumentSnapshot<RoleInterface> = await getReference("roles")
        .withConverter(roleConverter)
        .doc(roleId)
        .get();

      if (doc.exists) {
        return doc.data() as RoleInterface;
      } else {
        throw new Error(`Role #${roleId} not found`);
      }
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async createRole(role: RoleInterface, forceId = false): Promise<void> {
    try {
      if (forceId) {
        // eslint-disable-next-line prettier/prettier
        await getReference("roles")
          .withConverter(roleConverter)
          .doc(role.id)
          .set(role);
      } else {
        // eslint-disable-next-line prettier/prettier
        await getReference("roles")
          .withConverter(roleConverter)
          .doc()
          .set(role);
      }
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async updateRole(role: RoleInterface): Promise<void> {
    try {
      const batch = firebaseFirestore.batch();

      batch.set(getReference("roles").withConverter(roleConverter).doc(role.id), role);

      const linkedRole = linkedRoleToFirestore({
        id: role.id,
        name: role.name,
        readAll: role.readAll,
        readOwn: role.readOwn,
        create: role.create,
        updateAll: role.updateAll,
        updateOwn: role.updateOwn,
        deleteAll: role.deleteAll,
        deleteOwn: role.deleteOwn,
      });

      const users: UserInterface[] = await koruDb.getModule<UserModelInterface>("user").getUsersByRole(role.id);
      users.forEach((user: UserInterface) => {
        batch.update(getReference("users").doc(user.id), { role: linkedRole });
      });

      await batch.commit();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async deleteRole(roleId: string): Promise<void> {
    try {
      // eslint-disable-next-line prettier/prettier
      await getReference("roles")
        .doc(roleId)
        .delete();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
