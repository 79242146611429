import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "p-fluid formgrid grid" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = {
  class: "col-6",
  style: {"text-align":"right"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", null, [
      _createTextVNode(_toDisplayString(_ctx.renderTitlePrefix()) + " ", 1),
      _renderSlot(_ctx.$slots, "title")
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.handleSubmit()), ["prevent"])),
      onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
    }, [
      _renderSlot(_ctx.$slots, "form"),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Divider)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.props.record.id != 'new' && _ctx.userCanDelete(_ctx.requiredModule, _ctx.props.record))
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                label: _ctx.t('buttons.delete'),
                class: "p-button-danger p-uppercase",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirmDelete($event)))
              }, null, 8, ["label"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Button, {
            label: _ctx.t('buttons.close'),
            class: "p-button-primary p-uppercase",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmClose($event)))
          }, null, 8, ["label"]),
          ((_ctx.userCanCreate(_ctx.requiredModule) && _ctx.props.record.id == 'new') || _ctx.userCanUpdate(_ctx.requiredModule, _ctx.props.record))
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                label: _ctx.t('buttons.save'),
                type: "submit",
                class: "p-button-success p-uppercase ml-4"
              }, null, 8, ["label"]))
            : _createCommentVNode("", true)
        ])
      ])
    ], 32)
  ]))
}