import firebase from "firebase";

import { RecipeInterface, recipeConverter } from "@/features/modules/recipe/models/Recipe.interface";
import { RecipeModelInterface } from "../models/RecipeModel.interface";

import { getReference } from "@/core/modules/helpers";

export const recipeModel: RecipeModelInterface = {
  name: "recipe",

  async getRecipes(): Promise<RecipeInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<RecipeInterface> = await getReference("recipes")
        .withConverter(recipeConverter)
        .orderBy("titleIt")
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: RecipeInterface[] = [];
      snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<RecipeInterface>) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getRecipesByCategory(recipeCategoryId: string): Promise<RecipeInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<RecipeInterface> = await getReference("recipes")
        .withConverter(recipeConverter)
        .where("category.id", "==", recipeCategoryId)
        .orderBy("name")
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: RecipeInterface[] = [];
      snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<RecipeInterface>) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getLastReleasedRecipes(count: number): Promise<RecipeInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<RecipeInterface> = await getReference("recipes")
        .withConverter(recipeConverter)
        .where("release", "<=", new Date())
        .orderBy("release", "desc")
        .limit(count)
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: RecipeInterface[] = [];
      snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<RecipeInterface>) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getNextReleasedRecipes(count: number): Promise<RecipeInterface[]> {
    try {
      // eslint-disable-next-line prettier/prettier
      const snapshot: firebase.firestore.QuerySnapshot<RecipeInterface> = await getReference("recipes")
        .withConverter(recipeConverter)
        .where("release", ">", new Date())
        .orderBy("release")
        .limit(count)
        .get();

      if (snapshot.empty) {
        return [];
      }

      const data: RecipeInterface[] = [];
      snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot<RecipeInterface>) => {
        data.push(doc.data());
      });

      return data;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async getRecipe(recipeId: string): Promise<RecipeInterface> {
    try {
      // eslint-disable-next-line prettier/prettier
      const doc: firebase.firestore.DocumentSnapshot<RecipeInterface> = await getReference("recipes")
        .withConverter(recipeConverter)
        .doc(recipeId)
        .get();

      if (doc.exists) {
        return doc.data() as RecipeInterface;
      } else {
        throw new Error(`Recipe #${recipeId} not found`);
      }
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async createRecipe(recipe: RecipeInterface, forceId = false): Promise<void> {
    try {
      if (forceId) {
        // eslint-disable-next-line prettier/prettier
        await getReference("recipes")
          .withConverter(recipeConverter)
          .doc(recipe.id)
          .set(recipe);
      } else {
        // eslint-disable-next-line prettier/prettier
        await getReference("recipes")
          .withConverter(recipeConverter)
          .doc()
          .set(recipe);
      }
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async updateRecipe(recipe: RecipeInterface): Promise<void> {
    try {
      // eslint-disable-next-line prettier/prettier
      await getReference("recipes")
        .withConverter(recipeConverter)
        .doc(recipe.id)
        .set(recipe);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },

  async deleteRecipe(recipeId: string): Promise<void> {
    try {
      // eslint-disable-next-line prettier/prettier
      await getReference("recipes")
        .doc(recipeId)
        .delete();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
