export const recipeCategoryStoreTypes = {
  getters: {
    getUploadProgressValue: "GET_UPLOAD_PROGRESS_VALUE",
    isLoading: "IS_LOADING",
    isUploading: "IS_UPLOADING",
  },
  actions: {
    getRecipeCategories: "GET_RECIPE_CATEGORIES",
    getRecipeCategory: "GET_RECIPE_CATEGORY",
    createRecipeCategory: "CREATE_RECIPE_CATEGORY",
    updateRecipeCategory: "UPDATE_RECIPE_CATEGORY",
    deleteRecipeCategory: "DELETE_RECIPE_CATEGORY",
    uploadImage: "UPLOAD_IMAGE",
    deleteImage: "DELETE_IMAGE",
  },
  mutations: {
    setUploadProgressValue: "SET_UPLOAD_PROGRESS_VALUE",
    uploadStart: "UPLOAD_START",
    uploadStop: "UPLOAD_STOP",
  },
};
