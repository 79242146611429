import { createRouter, createWebHistory, Router } from "vue-router";

import { authStoreTypes } from "@/core/modules/auth/store/types";
import { koruStoreTypes } from "@/core/modules/store/types";
import { routes } from "./routes";
import { useAuthStore } from "@/core/modules/auth/store";
import { useKoruStore } from "@/core/modules/store";
import { UserInterface } from "@/core/modules/user/models/User.interface";

import { userHasRightWithType } from "@/core/modules/helpers";

export const koruRouter = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

koruRouter.beforeEach((to, from, next) => {
  const koruStore = useKoruStore();
  const koruAuth = useAuthStore();

  window.scrollTo(0, 0);

  if (to.name == "Error" || to.name == "NotFound" || to.name == "AccessDenied") {
    koruStore.commit(koruStoreTypes.mutations.editingStop);
  }

  if (koruStore.getters[koruStoreTypes.getters.isEditing]) {
    koruStore.commit(koruStoreTypes.mutations.openEditingDialog, to);
    return;
  }

  if (from.path != to.path) {
    koruStore.commit(koruStoreTypes.mutations.loadingStart);
  }

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    if (koruAuth.getter(authStoreTypes.getters.isLoggedIn) && to.path == "/login") {
      next("/");
    } else if (koruAuth.getter(authStoreTypes.getters.isLoggedIn)) {
      const user: UserInterface = koruAuth.getter(authStoreTypes.getters.getUser);
      const requiredModule = to.meta.requiredModule as string;
      const requiredRights = to.meta.requiredRights as Array<string>;

      if (userHasRightWithType(user, requiredModule, requiredRights)) {
        next();
      } else {
        next("/access-denied");
      }
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export const useKoruRouter = (): Router => {
  return koruRouter;
};
