import { authStoreTypes } from "@/core/modules/auth/store/types";
import { CollectionInterface } from "@/core/modules/database/models/Collection.interface";
import { useAuthStore } from "@/core/modules/auth/store";
import { UserInterface } from "@/core/modules/user/models/User.interface";

export function userCanRead(module: string, collection: CollectionInterface): boolean {
  const authStore = useAuthStore();
  const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);
  if (user == undefined) return false;
  if (user.role.readAll.indexOf(module) >= 0 || (user.role.readOwn.indexOf(module) >= 0 && collection.createdBy == user.id)) {
    return true;
  }
  return false;
}

export function userCanCreate(module: string): boolean {
  const authStore = useAuthStore();
  const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);
  if (user == undefined) return false;
  if (user.role.create.indexOf(module) >= 0) {
    return true;
  }
  return false;
}

export function userCanUpdate(module: string, collection: CollectionInterface): boolean {
  const authStore = useAuthStore();
  const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);
  if (user == undefined) return false;
  if (user.role.updateAll.indexOf(module) >= 0 || (user.role.updateOwn.indexOf(module) >= 0 && collection.createdBy == user.id)) {
    return true;
  }
  return false;
}

export function userCanDelete(module: string, collection: CollectionInterface): boolean {
  const authStore = useAuthStore();
  const user: UserInterface = authStore.getter(authStoreTypes.getters.getUser);
  if (user == undefined) return false;
  if (user.role.deleteAll.indexOf(module) >= 0 || (user.role.deleteOwn.indexOf(module) >= 0 && collection.createdBy == user.id)) {
    return true;
  }
  return false;
}
