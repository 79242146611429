import { GetterTree } from "vuex";

import { RecipeCategoryStateInterface } from "../models/RecipeCategoryState.interface";
import { recipeCategoryStoreTypes } from "./types";
import { KoruStateInterface } from "@/core/modules/store/models/KoruState.interface";
import { koruStoreTypes } from "@/core/modules/store/types";

export const getters: GetterTree<RecipeCategoryStateInterface, KoruStateInterface> = {
  [recipeCategoryStoreTypes.getters.isLoading]: (
    state: RecipeCategoryStateInterface,
    getters: GetterTree<KoruStateInterface, KoruStateInterface>
  ): boolean => {
    return getters[koruStoreTypes.getters.isLoading] as unknown as boolean;
  },
  [recipeCategoryStoreTypes.getters.isUploading]: (state: RecipeCategoryStateInterface): boolean => {
    return state.isUploading;
  },
  [recipeCategoryStoreTypes.getters.getUploadProgressValue]: (state: RecipeCategoryStateInterface): number => {
    return state.uploadProgressValue;
  },
};
