import { MutationTree } from "vuex";

import { RecipeCategoryStateInterface } from "../models/RecipeCategoryState.interface";
import { recipeCategoryStoreTypes } from "./types";

export const mutations: MutationTree<RecipeCategoryStateInterface> = {
  [recipeCategoryStoreTypes.mutations.uploadStart]: (state: RecipeCategoryStateInterface): void => {
    state.isUploading = true;
    state.uploadProgressValue = 0;
  },
  [recipeCategoryStoreTypes.mutations.uploadStop]: (state: RecipeCategoryStateInterface): void => {
    state.isUploading = false;
    state.uploadProgressValue = 0;
  },
  [recipeCategoryStoreTypes.mutations.setUploadProgressValue]: (state: RecipeCategoryStateInterface, value: number): void => {
    state.uploadProgressValue = value;
  },
};
